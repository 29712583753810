import React, { useEffect, useState } from 'react'
import LayoutComponent from '../BaseScreen'
import { useIntake } from '../../Context/IntakeContext'
import { useData } from '../../Context/DataContext'
import ChartComponent from '../../Components/Analytics/ChartComponent';
import SelectOptionComponent from '../../Components/Tables/SelectOptionComponent';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  Rectangle,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer
} from "recharts";
import axios from 'axios';
import { ChevronsDown, ChevronsUp } from 'react-feather';
import DisplayAccordianHeader from '../../Components/SortAndFilter/DisplayAccordianHeader';
import baseUrl from '../../Utils/baseUrl';
import CalendarSelectComponent from '../../Components/Inputs/CalendarSelectComponent';
import ButtonComponent from '../../Components/Inputs/ButtonComponent';
import BillingTotals from '../../Components/Tables/BillingAnalyticsTable';
import { DateUtilityFunctions } from '../../Utils/DateUtilityFunctions';
import { useAuth } from '../../Context/AuthContext';
import LoadingScreen from '../LoadingScreen';

interface IntakeProps {
  active: boolean;
  booked: string;
  checked_in: string;
  coordinator: string;
  created_date: string;
  date: string;
  date_of_birth: string;
  in_network_oop: number;
  inn_deductable: number;
  insurance: string;
  name: string;
  onn_deducatible: number;
  out_network_oop: number;
  payer_id: string;
  policy_id: string;
  prefix: string;
  source: string;
  summary_out: string;
}

interface AnalyticsDatum {
  clients: number;
  date: string;
  Arrived: number;
  MIA: number;
  BD: number;
  'Pending Arrival': number;
  Approved: number;
  Denied: number;
  'Private Pay': number;
  'Approved No RTC': number;
  'Approved LB No RTC': number;
  'Pending Approval': number;
  'Approved DB': number;
  'Total Approved': number;
  waiting_arrival: number;
  Booked: number;
  'Incoming Clients': number;
  arrived_approved_percent: number;
  approved_total_percent: number;
  arrived_total_percent: number;
}

export const keysAndLabelsCoordinators = [
  {key: 'value',label: 'Dylan Finch'},
  {key: 'Whitney  Meeder',label: 'Whitney M.'},
  {key: 'Cynthia Chacon',label: 'Cynthia C.'},
  {key: 'Branden  Cox ',label: 'Branden C.'},
  {key: 'randi Hamilton',label: 'Randi H.'},
  {key: 'Kelly Mahaffey',label: 'Kelly M.'},
  {key: 'Kevyn Howard',label: 'Kevyn H.'},
  {key: 'MICHAEL CROTHERS', label: 'Michael C.'}
]

const keysAndLabelsOverall = [
  { key: 'Booked', label: 'Booked' },
  { key: 'Denied', label: 'Denied' },
  { key: 'Pending', label: 'Pending' },
  { key: 'Total', label: 'Total' }
];

const IntakeAnalytivsScreen = () => {

  const {allUsers} = useAuth()
  const {intakeRecords} = useIntake()
  const {timespan, fetchAnticipatedData, anticipatedDischargeData} = useIntake()
  const [fetchingData, setFetchingData] = useState<boolean>(true)

  const statusOPtions = [
    'Pending Approval',
    'Total Approved',
    'Denied',
    'Private Pay'
  ]

  const approvedOptions = [
    'Approved',
    'Approved No RTC',
    'Approved LB No RTC',
    'Approved DB'
  ]

  const checkedInOptions = [
    'Arrived',
    'Pending Arrival',
    'MIA',
    'BD'
  ]

  useEffect(() => {
    processIntakeInformation(intakeRecords)
    grabCensusData()
    grabIntakeConversionData()
    fetchAnticipatedData();
  }, [])


  const currentDate = new Date()
  const oneWeekAgo = new Date(currentDate)
  oneWeekAgo.setDate(currentDate.getDate() - 7);
  const oneMonthAgo = new Date(currentDate)
  oneMonthAgo.setDate(currentDate.getDate() - 31);
  

  const [analyticsData, setAnalyticsData] = useState<any>({})
  const [censusData, setCensusData] = useState<any>({})
  const [conversionData, setConversionData] = useState<any>([])
  const [pieBookedData, setPieBookedData] = useState<any>([])
  const [pieApprovedData, setPieApprovedData] = useState<any>([])
  const [overallPieData, setOverallPieData] = useState<any>([])
  const [pieStartDate, setPieStartDate] = useState<Date>(oneWeekAgo)
  const [pieEndDate, setPieEndDate] = useState<Date>(currentDate)
  const [statusStartDate, setStatusStartDate] = useState<Date>(oneMonthAgo)
  const [statusEndDate, setStatusEndDate] = useState<Date>(currentDate)

  const [selectedStatus, setSelectedStatus] = useState<string>('Pending Approval')
  const [selectedApproved, setSelectedApproved] = useState<string>('Approved')
  const [selectedCheckedIn, setSelectedCheckedIn] = useState<string>('Arrived')

  const [showStatus, setShowStatus] = useState<boolean>(false)
  const [showApproved, setShowApproved] = useState<boolean>(false)
  const [showCheckedIn, setShowCheckedIn] = useState<boolean>(false)
  const [showCensus, setShowCensus] = useState<boolean>(false)
  const [showConversion, setShowConversion] = useState<boolean>(false)
  const [showAnticipated, setShowAnticipated] = useState<boolean>(false)

  
  const keysAndLabelsStatus = [
      { key: 'Pending Approval', label: 'Pending Approval' },
      { key: 'Total Approved', label: 'Total Approved' },
      { key: 'Denied', label: 'Denied' },
      { key: 'Private Pay', label: 'Private Pay' },
    ]
  
  const keysAndLabelsApproved = [
    {key: 'Approved',label: 'Approved'},
    {key: 'Approved No RTC', label: 'Approved No RTC'},
    {key: 'Approved LB No RTC',label: 'Approved LB No RTC'},
    {key: 'Approved DB',label: 'Approved DB'},
  ]

  const keysAndLabelsCheckedIn = [
    {key: 'Arrived',label: 'Arrived'},
    {key: 'Pending Arrival',label: 'Pending Arrival'},
    {key: 'MIA',label: 'MIA'},
    {key: 'BD',label: 'BD'},
  ]

  const keysAndLabelsCensus = [
    {key: 'Total Patients',label: 'Total Patients'},
    {key: 'New Admits',label: 'New Admits'},
    {key: 'RTC',label: 'RTC'},
    {key: 'Detox',label: 'Detox'},
    {key: 'PHP',label: 'PHP'},
    {key: 'IOP',label: 'IOP'},
  ]

  const keysAndLabelsAnticipatedDischarge = [
    {key: 'Axis',label: 'Axis'},
    {key: 'Affinity',label: 'Affinity'},
    {key: 'Beachside',label: 'Beachside'},
  ]

  const keysAndLabelsCoordinators = [
    {key: 'Dylan Finch',label: 'Dylan F.'},
    {key: 'Whitney  Meeder',label: 'Whitney M.'},
    {key: 'Cynthia Chacon',label: 'Cynthia C.'},
    {key: 'Branden  Cox ',label: 'Branden C.'},
    {key: 'randi Hamilton',label: 'Randi H.'},
    {key: 'Kelly Mahaffey',label: 'Kelly M.'},
    {key: 'Kevyn Howard',label: 'Kevyn H.'},
    {key: 'MICHAEL CROTHERS', label: 'Michael C.'}
  ]

  const COLORS = [
    '#00C49F', '#FF8042', '#FFBB28'
  ];

  const colors = {
    'Pending Approval': "bg-custom-macaroni",
    'Total Approved': "bg-custom-orange",
    'Denied': "bg-custom-red",
    'Private Pay': "bg-custom-yellow",
    'Approved': 'bg-custom-macaroni',
    'Approved No RTC': "bg-custom-orange",
    'Approved LB No RTC': "bg-custom-red",
    'Approved DB': "bg-custom-yellow",
    'Arrived': 'bg-custom-macaroni',
    'Pending Arrival': 'bg-custom-orange',
    'MIA': 'bg-custom-red',
    'BD': 'bg-custom-yellow',
    'Total Patients': 'bg-custom-red',
    'New Admits': 'bg-custom-green',
    'Detox': 'bg-custom-teal',
    'RTC': 'bg-custom-blue',
    'PHP':'bg-custom-orange',
    'IOP': 'bg-custom-violet',
    'Axis': 'bg-custom-blue',
    'Affinity': 'bg-custom-red',
    'Beachside': 'bg-custom-green'
  }

  const coordinatorColors = {
    'Dylan Finch': 'bg-custom-blue',
    'Whitney  Meeder': 'bg-custom-red',
    'Cynthia Chacon': 'bg-custom-teal',
    "Branden  Cox ": 'bg-custom-violet',
    'randi Hamilton': 'bg-custom-lime',
    'Kelly Mahaffey': 'bg-custom-brick',
    'Kevyn Howard': 'bg-custom-orange',
    'MICHAEL CROTHERS': 'bg-custom-pink'
  }

  
  const COLORS2:any = 
    {
      'Dylan Finch': '#0000FF',
      'Whitney  Meeder': '#FF4500',
      'Cynthia Chacon': '#008080',
      "Branden  Cox ": '#7F00FF',
      'randi Hamilton': '#32CD32',
      'Kelly Mahaffey': '#A52A2A',
      'Kevyn Howard': '#fc8003',
      'MICHAEL CROTHERS': '#FF1493'
    }

  const grabCensusData = () => {
    setFetchingData(true);
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/census/analytics`,
      headers: { }
    };
    
    axios.request(config)
    .then((response) => {
      response.data.forEach((facility: any) => {
        const totalPatients = Object.entries(facility).reduce((acc, [key, value]) => {
          if (key !== 'facility' && typeof value === 'number') {
            return acc + value;
          }
          return acc;
        }, 0);
        facility['Total Patients'] = totalPatients;
      });
      setCensusData(response.data)
      setFetchingData(false)
    })
    .catch((error) => {
      console.log(error);
      setFetchingData(false)
    });
  }
  
  const processIntakeInformation = (intakeRecords: IntakeProps[] | null) => {
    let analytics: any = {};

    // const startDate = new Date();
    // switch (timespan) {
    //   case '1 Week':
    //     startDate.setDate(startDate.getDate() - 7);
    //     break;
    //   case '1 Month':
    //     startDate.setMonth(startDate.getMonth() - 1);
    //     break;
    //   case '3 Months':
    //     startDate.setMonth(startDate.getMonth() - 3);
    //     break;
    //   case '6 Months':
    //     startDate.setMonth(startDate.getMonth() - 6);
    //     break;
    //   case '1 Year':
    //     startDate.setFullYear(startDate.getFullYear() - 1);
    //     break;
    //   default:
    //     break;
    // }

    function convertDobDateToMMDDYYYY(dateString: string) {
      const date = new Date(dateString);
      const mm = String(date.getUTCMonth() + 1).padStart(2, '0'); 
      const dd = String(date.getUTCDate() + 1).padStart(2, '0'); 
      return `${mm}/${dd}`;
    }

    intakeRecords?.forEach(item => {
      const { coordinator, date, booked, checked_in } = item;
      const currentDate = new Date(date);
      if (statusStartDate <= currentDate && statusEndDate >= currentDate) {
        if (!analytics[convertDobDateToMMDDYYYY(date)]) {
          analytics[convertDobDateToMMDDYYYY(date)] = {
            clients: 0,
            date: convertDobDateToMMDDYYYY(date),
            Arrived: 0,
            MIA: 0,
            BD: 0,
            'Pending Arrival': 0,
            Approved: 0,
            Denied: 0,
            'Private Pay': 0,
            'Approved No RTC': 0,
            'Approved LB No RTC': 0,
            'Pending Approval': 0,
            'Approved DB': 0,
            'Total Approved': 0,
            waiting_arrival: 0,
            'Booked': 0,
            'Incoming Clients': 0,
            'Total Calls': 0,
            arrived_approved_percent: 0,
            approved_total_percent: 0,
            arrived_total_percent: 0,
          };
        }

        analytics[convertDobDateToMMDDYYYY(date)]['Total Calls']++;

        if (booked === 'Arrived') analytics[convertDobDateToMMDDYYYY(date)].Arrived++;
        if (booked === 'MIA') analytics[convertDobDateToMMDDYYYY(date)].MIA++;
        if (booked === 'BD') analytics[convertDobDateToMMDDYYYY(date)].BD++;
        if (booked === 'Pending') analytics[convertDobDateToMMDDYYYY(date)]['Pending Arrival']++;
        
        if (checked_in === 'Pending') analytics[convertDobDateToMMDDYYYY(date)]['Pending Approval']++;
        if (checked_in === 'Approved') analytics[convertDobDateToMMDDYYYY(date)].Approved++;
        if (checked_in === 'Denied') analytics[convertDobDateToMMDDYYYY(date)].Denied++;
        if (checked_in === 'Private Pay') analytics[convertDobDateToMMDDYYYY(date)]['Private Pay']++;
        if (checked_in === 'Approved DB') analytics[convertDobDateToMMDDYYYY(date)]['Approved DB']++;
        if (checked_in === 'Approved No RTC') analytics[convertDobDateToMMDDYYYY(date)]['Approved No RTC']++;
        if (checked_in === 'Approved LB No RTC') analytics[convertDobDateToMMDDYYYY(date)]['Approved LB No RTC']++;
  
        if (checked_in === 'Approved LB No RTC' || 
            checked_in === 'Approved DB' || 
            checked_in === 'Approved No RTC' || 
            checked_in === 'Approved' ) analytics[convertDobDateToMMDDYYYY(date)]['Total Approved']++;

      }

    });
    let newData: any = []
    Object.values(analytics).map((record) => {
      newData.push(record)
    })
    setAnalyticsData(newData.reverse())
  }

  const {currentTimeZone} = useData()

  const grabIntakeConversionData = () => {
    const body = {
      "tz": currentTimeZone
    }

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/intake/conversion`,
      headers: { },
      data: body
    };

    axios.request(config)
    .then((response: any) => {
      console.log(response.data, "this is conversion chart data")
      setConversionData(response.data)
    })
    .catch((error: any) => {
      console.error(error)
    })
  }

  // const coordinatorConversionData = (conversionData: any) => {
  //   const processedData: any = {
  //     Booked: {},
  //     Approved: {}
  //   };
  
  //   conversionData.forEach((item: any) => {
  //     const coordinatorId = item.coordinator;
  
  //     const user = allUsers?.find(user => user.userid === coordinatorId);
  //     if (!user || user.name === 'Unknown User') {
  //       return; // Exclude coordinators without a name or with 'Unknown User'
  //     }
  
  //     const coordinatorName = user.name;
  
  //     if (item.status === 'Booked') {
  //       if (!processedData.Booked[coordinatorName]) {
  //         processedData.Booked[coordinatorName] = 0;
  //       }
  //       processedData.Booked[coordinatorName] += 1;
  //     }
  
  //     if (item.status === 'Approved') {
  //       if (!processedData.Approved[coordinatorName]) {
  //         processedData.Approved[coordinatorName] = 0;
  //       }
  //       processedData.Approved[coordinatorName] += 1;
  //     }
  //   });
  
  //   const finalResponse: any = [];
  //   Object.keys(processedData).forEach(status => {
  //     Object.keys(processedData[status]).forEach(coordinator => {
  //       finalResponse.push({
  //         name: `${status} - ${coordinator}`,
  //         value: processedData[status][coordinator]
  //       });
  //     });
  //   });
  //   setPieData(finalResponse);
  // };

  const filterCoordinatorsData = (conversionData: any) => {
    const processedData: any = {};
  
    conversionData.forEach((item: any) => {
      const coordinatorId = item.coordinator;
      const user = allUsers?.find(user => user.userid === coordinatorId);
      if (!user || user.name === 'Unknown User') {
        return; // Exclude coordinators without a name or with 'Unknown User'
      }
  
      const coordinatorName = user.name;
      if (!processedData[coordinatorName]) {
        processedData[coordinatorName] = { booked: 0, pending: 0 };
      }
  
      if (item.status === 'Booked') {
        processedData[coordinatorName].booked += 1;
      } else if (item.booked === 'Yellow Stripe') {
        processedData[coordinatorName].pending += 1;
      }
    });
  
    return Object.keys(processedData).map(coordinator => {
      const { booked, pending } = processedData[coordinator];
      const total = booked + pending;
      const conversionRate = total > 0 ? (booked / total) * 100 : 0;
      return {
        name: coordinator,
        value: parseFloat(conversionRate.toFixed(2))
      };
    });
    };

  const filterDataByTimeFrame = (timeFrame: any) => {
    const currentDate = new Date();
    let startDate: Date;

    switch (timeFrame) {
      // case ''
      case '1 Week':
        startDate = new Date(currentDate.setDate(currentDate.getDate() - 7));
        break;
      case '1 Month':
        startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
        break;
      case '3 Months':
        startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 3));
        break;
      case '6 Months':
        startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 6));
        break;
      default:
        startDate = new Date(0); // Include all dates if no valid time frame is provided
        break;
    }
    return conversionData.filter((item: any) => new Date(item.date) >= startDate);
  };

  const filterDataCustom = (startDate: Date, endDate: Date) => {

    return conversionData.filter((item: any) => {
      const itemDate = new Date(item.date);
      return itemDate >= startDate && itemDate<= endDate
    })
  }

  const aggregateData = (filteredData: any) => {
    const aggregatedData: any = {
      Booked: { total: 0, coordinators: {} },
      Denied: { total: 0, coordinators: {} },
      Pending: { total: 0, coordinators: {} }
    };
  
    filteredData.forEach((item: any) => {
      const coordinator = allUsers?.find(user => user.userid === item.coordinator)?.name || 'Unknown';
      
      if (item.status === 'Booked') {
        aggregatedData.Booked.total += 1;
        aggregatedData.Booked.coordinators[coordinator] = (aggregatedData.Booked.coordinators[coordinator] || 0) + 1;
      } else if (item.status === 'Denied') {
        aggregatedData.Denied.total += 1;
        aggregatedData.Denied.coordinators[coordinator] = (aggregatedData.Denied.coordinators[coordinator] || 0) + 1;
      } else if (item.booked === 'Yellow Stripe') {
        aggregatedData.Pending.total += 1;
        aggregatedData.Pending.coordinators[coordinator] = (aggregatedData.Pending.coordinators[coordinator] || 0) + 1;
      }
    });
  
    return Object.entries(aggregatedData).map(([name, data]) => ({
      name,
      value: (data as any).total,
      coordinators: (data as any).coordinators
    }));
  };

  const filterConversionByTimeCustom = (startDate:Date, endDate:Date) => {
    const filteredData = filterDataCustom(startDate, endDate);
    const aggregatedData = aggregateData(filteredData);
    setOverallPieData(aggregatedData);
    setPieBookedData(filterCoordinatorsData(filteredData));
  };
  
  const renderOverallCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index, name, value
  }: any) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 20;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <g>
        <line x1={cx} y1={cy} x2={x} y2={y} stroke="grey" />
        <text
          x={x}
          y={y}
          fill="grey"
          textAnchor={x > cx ? 'start' : 'end'}
          color='grey'
          dominantBaseline="central"
        >
          {`${name} (${value})`}
        </text>
        <text
          x={x}
          y={y}
          dy={18}
          fill="grey"
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(2)}%`}
        </text>
      </g>
    );
  };


  const renderPieCustomizedLabel = ({
    cx, cy, midAngle, outerRadius, percent, name, value, index
  }: any) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 20;
    const x = cx + (radius + 10) * Math.cos(-midAngle * RADIAN);
    const y = cy + (radius + 20) * Math.sin(-midAngle * RADIAN);
  
    const lineX = cx + (radius) * Math.cos(-midAngle * RADIAN);
    const lineY = cy + (radius) * Math.sin(-midAngle * RADIAN);

  
    return (
      <g>
        <line x1={lineX} y1={lineY} x2={x} y2={y} stroke="grey" />
        <text
          x={x}
          y={y}
          fill='grey'
          textAnchor={x > cx ? 'start' : 'end'}
          fontSizeAdjust={8}
          dominantBaseline="central"
        >
          {`${name} (${value})`}
        </text>
      </g>
    );
  };

  const customTooltipBooked = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const total = pieBookedData.reduce((sum: any, entry: any) => sum + entry.value, 0)
      return (
        <div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: "10px", border: "1px solid #ccc" }}>
          {payload.map((entry: any, index: number) => (
            <div key={`item-${index}`}>
              <span>{entry.name}: </span>
              <span>{`${entry.value} (${((entry.value / total) * 100).toFixed(2)}%)`}</span>
            </div>
          ))}
        </div>
      );
    }
    return null;
  }

  const customTooltipConversionRate = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: "10px", border: "1px solid #ccc" }}>
          {payload.map((entry: any, index: number) => (
            <div key={`item-${index}`}>
              <span>{entry.name}: </span>
              <span>{`${entry.value}%`}</span>
            </div>
          ))}
        </div>
      );
    }
    return null;
  }

  const customTooltipOverall = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: "10px", border: "1px solid #ccc" }}>
          <p className="label">{`${data.name}: ${data.value}`}</p>
          <ul style={{ padding: 0, listStyle: 'none' }}>
            {Object.entries(data.coordinators).map(([coordinator, value]) => (
              <li key={coordinator}>{`${coordinator}: ${value}`}</li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  };


  useEffect(() => {
    filterConversionByTimeCustom(pieStartDate, pieEndDate)
  }, [pieStartDate, pieEndDate])

  useEffect(() => {
    processIntakeInformation(intakeRecords)
  }, [statusStartDate, statusEndDate])
  
  

  return (
    fetchingData 
    ? <LoadingScreen /> 
    :
    <LayoutComponent
      header={null} // Render your custom header component here
      content={
        <div className="w-full px-4" style={{ height: 'calc(100vh - 5.5rem)' }}>
          <div className="h-full overflow-scroll rounded-lg mt-2">
          <DisplayAccordianHeader label={'Status'} display={showStatus} onChange={() => {setShowStatus(!showStatus)}}/>
          {
            showStatus
              ? <div style={{marginBottom: 36, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div className="h-10 mt-3 px-2 w-full flex flex-col justify-center bg-stone-200 dark:bg-stone-700 rounded-md mb-4">
                    <div className='w-full flex flex-row items-center justify-center gap-5'>
                      <div className='flex flex-row text-black dark:text-white items-center'>
                        <p className='text-md mr-3'>Start Date:</p>
                        <CalendarSelectComponent selectedDate={statusStartDate} handleDateChange={setStatusStartDate}/>
                      </div>
                      <div className='flex flex-row text-black dark:text-white m-1  items-center'>
                        <p className='text-md mr-3'>End Date:</p>
                        <CalendarSelectComponent selectedDate={statusEndDate} handleDateChange={setStatusEndDate}/>
                      </div>
                      <div className='flex flex-row text-black dark:text-white m-1  items-center'>
                        <p className='text-md mr-3'>Table:</p>
                        <SelectOptionComponent  options={statusOPtions} value={selectedStatus} onChange={setSelectedStatus}/>
                      </div>
                    </div>
                </div>
                  <h1 className='text-3xl text-black dark:text-white font-bold mt-8 mb-3'>Status</h1>
                  <BarChart
                    width={window.innerWidth * .75}
                    height={((2/3) * (window.innerWidth * .25))}
                    data={analyticsData}
                    margin={{
                      top: 5,
                      right: 150,
                      left: 100,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="grey" />
                    <XAxis dataKey="date"  tick={{ fill: 'grey' }} />
                    <YAxis tick={{fill: 'grey'}}/>
                    <Tooltip />
                    <Legend />
                    {
                      selectedStatus === 'Pending Approval'
                        ? <Bar
                            strokeWidth={2}
                            type="monotone"
                            dataKey="Pending Approval"
                            fill="#fcba03"
                            label="Pending Approval"
                          />
                        : selectedStatus === 'Total Approved'
                            ? <Bar
                                strokeWidth={2}
                                type="monotone"
                                dataKey="Total Approved"
                                fill="#fc8003"
                                label="Total Approved"
                              />
                            : selectedStatus === 'Denied'
                                ? <Bar
                                    strokeWidth={2}
                                    type="monotone"
                                    dataKey="Denied"
                                    fill="red"
                                    label="Denied"
                                  />
                                : <Bar
                                    strokeWidth={2}
                                    type="monotone"
                                    dataKey="Private Pay"
                                    fill="#fcd303"
                                    label="Private Pay"
                                  />
                    }
                    
                  </BarChart>
                  <BillingTotals data={analyticsData} keysAndLabels={keysAndLabelsStatus} colors={colors} />
                </div>
              : null
          }
          <DisplayAccordianHeader label={'Status Approved'} display={showApproved} onChange={() => {setShowApproved(!showApproved)}}/>
          {
            showApproved
              ? <div style={{marginBottom: 36, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div className="h-10 mt-3 px-2 w-full flex flex-col justify-center bg-stone-200 dark:bg-stone-700 rounded-md mb-4">
                  <div className='w-full flex flex-row items-center justify-center gap-5'>
                      <div className='flex flex-row text-black dark:text-white items-center'>
                        <p className='text-md mr-3'>Start Date:</p>
                        <CalendarSelectComponent selectedDate={statusStartDate} handleDateChange={setStatusStartDate}/>
                      </div>
                      <div className='flex flex-row text-black dark:text-white m-1  items-center'>
                        <p className='text-md mr-3'>End Date:</p>
                        <CalendarSelectComponent selectedDate={statusEndDate} handleDateChange={setStatusEndDate}/>
                      </div>
                      <div className='flex flex-row text-black dark:text-white m-1  items-center'>
                        <p className='text-md mr-3'>Table:</p>
                        <SelectOptionComponent  options={approvedOptions} value={selectedApproved} onChange={setSelectedApproved}/>
                      </div>
                    </div>
                </div>
                  <h1 className='text-3xl text-black dark:text-white font-bold mt-8 mb-3'>Approved Status</h1>
                  <BarChart
                    width={window.innerWidth * .75}
                    height={((2/3) * (window.innerWidth * .25))}
                    data={analyticsData}
                    margin={{
                      top: 5,
                      right: 150,
                      left: 100,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="grey" />
                    <XAxis dataKey="date"  tick={{ fill: 'grey' }} />
                    <YAxis tick={{ fill: 'grey' }} />
                    <Tooltip />
                    <Legend />
                    {
                      selectedApproved === 'Approved'
                        ? <Bar
                            strokeWidth={2}
                            type="monotone"
                            dataKey="Approved"
                            fill="#fcba03"
                            label="Approved"
                          />
                        : selectedApproved === 'Approved No RTC'
                            ? <Bar
                                strokeWidth={2}
                                type="monotone"
                                dataKey="Approved No RTC"
                                fill="#fc8003"
                                label="Approved No RTC"
                              />
                            : selectedApproved === 'Approved LB No RTC'
                                ? <Bar
                                    strokeWidth={2}
                                    type="monotone"
                                    dataKey="Approved LB No RTC"
                                    fill="red"
                                    label="Approved LB No RTC"
                                  />
                                : <Bar
                                    strokeWidth={2}
                                    type="monotone"
                                    dataKey="Approved DB"
                                    fill="#fcd303"
                                    label="Approved DB"
                                  />
                    }
                  </BarChart>
                  <BillingTotals data={analyticsData} keysAndLabels={keysAndLabelsApproved} colors={colors} />
                </div>
              : null
          }
          <DisplayAccordianHeader label={'Checked In'} display={showCheckedIn} onChange={() => {setShowCheckedIn(!showCheckedIn)}}/>
          {
            showCheckedIn
              ? <div style={{marginBottom: 36, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div className="h-10 mt-3 px-2 w-full flex flex-col justify-center bg-stone-200 dark:bg-stone-700 rounded-md mb-4">
                    <div className='w-full flex flex-row items-center justify-center gap-5'>
                      <div className='flex flex-row text-black dark:text-white items-center'>
                        <p className='text-md mr-3'>Start Date:</p>
                        <CalendarSelectComponent selectedDate={statusStartDate} handleDateChange={setStatusStartDate}/>
                      </div>
                      <div className='flex flex-row text-black dark:text-white m-1  items-center'>
                        <p className='text-md mr-3'>End Date:</p>
                        <CalendarSelectComponent selectedDate={statusEndDate} handleDateChange={setStatusEndDate}/>
                      </div>
                      <div className='flex flex-row text-black dark:text-white m-1  items-center'>
                        <p className='text-md mr-3'>Table:</p>
                        <SelectOptionComponent  options={checkedInOptions} value={selectedCheckedIn} onChange={setSelectedCheckedIn}/>
                      </div>
                    </div>
                </div>
                  <h1 className='text-3xl text-black dark:text-white font-bold mt-8 mb-3'>Checked In</h1>
                  <BarChart
                    width={window.innerWidth * .75}
                    height={((2/3) * (window.innerWidth * .25))}
                    data={analyticsData}
                    margin={{
                      top: 5,
                      right: 150,
                      left: 100,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="grey" />
                    <XAxis dataKey="date"  tick={{ fill: 'grey' }} />
                    <YAxis tick={{ fill: 'grey' }} />
                    <Tooltip />
                    <Legend />
                    {
                      selectedCheckedIn === 'Arrived'
                        ? <Bar
                            strokeWidth={2}
                            type="monotone"
                            dataKey="Arrived"
                            fill="#fcba03"
                            label="Arrived"
                          />
                        : selectedCheckedIn === 'Pending Arrival'
                            ? <Bar
                                strokeWidth={2}
                                type="monotone"
                                dataKey="Pending Arrival"
                                fill="#fc8003"
                                label="Arrived"
                              />
                            : selectedCheckedIn === 'MIA'
                                ? <Bar
                                    strokeWidth={2}
                                    type="monotone"
                                    dataKey="MIA"
                                    fill="red"
                                    label="MIA"
                                  />
                                : <Bar
                                    strokeWidth={2}
                                    type="monotone"
                                    dataKey="BD"
                                    fill="#fcd303"
                                    label="BD"
                                  />
                    }
                  </BarChart>
                  <BillingTotals data={analyticsData} keysAndLabels={keysAndLabelsCheckedIn} colors={colors}/>
                </div>
              : null
          }
          <DisplayAccordianHeader label={'Census'} display={showCensus} onChange={() => {setShowCensus(!showCensus)}}/>
          {
            showCensus
              ? <div style={{marginBottom: 36, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <h1 className='text-3xl text-black dark:text-white font-bold mt-8 mb-3'>Census</h1>
                  <BarChart
                    width={window.innerWidth * 0.65}
                    height={((2 / 3) * (window.innerWidth * 0.25))}
                    data={censusData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="grey" />
                    <XAxis dataKey="facility" tick={{ fill: 'grey' }} />
                    <YAxis tick={{ fill: 'grey' }} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Total Patients" fill="red" activeBar={<Rectangle fill="red" stroke="red" />}/>
                    <Bar dataKey="New Admits" fill="green" activeBar={<Rectangle fill="green" stroke="green" />}/>
                    <Bar dataKey="RTC" fill="blue" activeBar={<Rectangle fill="blue" stroke="blue" />}/>
                    <Bar dataKey="Detox" fill="teal" activeBar={<Rectangle fill="teal" stroke="teal" />}/>
                    <Bar dataKey="PHP" fill="orange" activeBar={<Rectangle fill="orange" stroke="orange" />}/>
                    <Bar dataKey="IOP" fill="violet" activeBar={<Rectangle fill="violet" stroke="violet" />}/>
                  </BarChart>
                  <BillingTotals data={censusData} keysAndLabels={keysAndLabelsCensus} colors={colors}/>
                </div>
              : null
          }
          <DisplayAccordianHeader label={'Anticipated Discharge'} display={showAnticipated} onChange={() => {setShowAnticipated(!showAnticipated)}}/>
          {
            showAnticipated
              ? <div style={{marginBottom: 36, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <h1 className='text-3xl text-black dark:text-white font-bold mt-8 mb-3'>Anticipated Discharge Data</h1>
              <BarChart
                width={window.innerWidth * 0.65}
                height={((2 / 3) * (window.innerWidth * 0.25))}
                data={anticipatedDischargeData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="grey" />
                <XAxis dataKey="Date" tick={{ fill: 'grey' }} />
                <YAxis tick={{ fill: 'grey' }} />
                <Tooltip />
                <Legend />
                <Bar dataKey="Axis" fill="blue" activeBar={<Rectangle fill="blue" stroke="blue" />}/>
                <Bar dataKey="Affinity" fill="red" activeBar={<Rectangle fill="red" stroke="red" />}/>
                <Bar dataKey="Beachside" fill="green" activeBar={<Rectangle fill="green" stroke="green" />}/>
              </BarChart>
              <BillingTotals data={anticipatedDischargeData} keysAndLabels={keysAndLabelsAnticipatedDischarge} colors={colors}/>
            </div>
          : null
          }
          <DisplayAccordianHeader label={'Conversion'} display={showConversion} onChange={() => { setShowConversion(!showConversion); filterConversionByTimeCustom(pieStartDate, pieEndDate)}} />
          {
            showConversion
              ? <div className='max-width flex flex-col overflow-auto'>
                <div className="h-10 mt-3 px-2 w-full flex flex-col justify-center bg-stone-200 dark:bg-stone-700 rounded-md mb-4">
                  <div className='w-full flex flex-row items-center justify-center gap-5'>
                      <div className='flex flex-row text-black dark:text-white items-center'>
                        <p className='text-md mr-3'>Start Date:</p>
                        <CalendarSelectComponent selectedDate={pieStartDate} handleDateChange={setPieStartDate}/>
                      </div>
                      <div className='flex flex-row text-black dark:text-white m-1  items-center'>
                        <p className='text-md mr-3'>End Date:</p>
                        <CalendarSelectComponent selectedDate={pieEndDate} handleDateChange={setPieEndDate}/>
                      </div>
                    </div>
                    {/* <div className='w-full flex flex-row items-center justify-start'>
                      <SelectOptionComponent value={pieTime} onChange={handlePieTimeChange} options={['1 Day','1 Week', '1 Month', '3 Months', '6 Months']} />
                    </div> */}
                  </div>

                    {(() => {
                      const sortedOverallPieData = overallPieData.sort((a: any, b: any) => a.name.localeCompare(b.name));
                      const sortedApprovedPieData = pieApprovedData.sort((a: any, b: any) => a.name.localeCompare(b.name));
                      const sortedBookedPieData = pieBookedData.sort((a: any, b: any) => a.name.localeCompare(b.name));


                      return (
                        <div className='flex flex-col min-w-full items-center overflow-x-auto w-full'>
  <div className='w-full mb-2 justify-center align-middle'>
    <h1 className='text-primary text-center text-xl mt-2'>Overall Data</h1>
    <div className='w-full'>
      <ResponsiveContainer className='w-full' width='100%' height={400}>
        <PieChart>
          <Pie
            data={sortedOverallPieData}
            dataKey='value'
            nameKey='name'
            outerRadius={150}
            fill="#8884d8"
            label={renderOverallCustomizedLabel}
            labelLine={false}
          >
            {sortedOverallPieData.map((entry: any, index: any) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip content={customTooltipOverall} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  </div>
  <div className='flex flex-col w-full'> 
    <h1 className='text-center text-primary text-xl mt-2'>Conversion Rate by Coordinator</h1>
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={sortedBookedPieData}
          dataKey='value'
          nameKey='name'
          outerRadius={140}
          fill="#FF8042"
          label={renderPieCustomizedLabel}
          labelLine={false}
          stroke='grey'
        >
          {sortedBookedPieData.map((entry: any, index: any) => {
            const color = COLORS2[entry.name]
            return (
            <Cell key={`cell-${index}`} fill={ color || "#FF8042"} />
          )})}
        </Pie>
        <Tooltip content={customTooltipConversionRate}/>
      </PieChart>
    </ResponsiveContainer>
  </div>
</div>
                      );
                    })()}
                </div>
              : null
          }
          </div>
        </div>
  } // Render your custom content component here
    />
  )
}

export default IntakeAnalytivsScreen
